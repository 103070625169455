<template>
  <b-card no-body>
    <b-overlay :show="loading" rounded="sm" variant="light">
      <b-card-header>
        <div>
          <b-card-title class="mb-1">
            {{ $t("field.payment") }}
          </b-card-title>
        </div>

        <b-button-group class="mt-1 mt-sm-0">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            :variant="
              option.value == query.period ? 'primary' : 'outline-primary'
            "
            size="sm"
            v-for="option in filterOptions"
            :key="option.value"
            @click="changeDate(option.value)"
          >
            {{ $t(option.label) }}
          </b-button>
        </b-button-group>
      </b-card-header>

      <b-card-body>
        <chartjs-component-bar-chart
          :key="`payment-chart-${key}`"
          :height="400"
          :data="data"
          :options="chartjsData.barChartOptions"
        />
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardSubTitle,
  BCardTitle,
  BButtonGroup,
  BButton,
  BOverlay,
} from "bootstrap-vue";
import ChartjsComponentBarChart from "@/components/charts-components/ChartjsComponentBarChart.vue";
import chartjsData from "./chartjsData";
import Ripple from "vue-ripple-directive";
import Repository from "@/repositories/RepositoryFactory";
import moment from "moment";

const DashboardRepository = Repository.get("dashboard");

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardSubTitle,
    BCardTitle,
    BButtonGroup,
    BButton,
    BOverlay,
    ChartjsComponentBarChart,
  },
  directives: {
    Ripple,
  },
  computed: {
    currentLocale() {
      return this.$i18n.locale;
    },
    userData: function () {
      return this.$store.state.profile.email
        ? this.$store.state.profile
        : JSON.parse(localStorage.getItem("userData"));
    },
  },
  watch: {
    currentLocale(value, oldValue) {
      if (oldValue && value && this.chartData?.data?.length) {
        this.mapChartData(this.chartData);
      }
    },
    "query.period"() {
      this.index();
    },
  },
  data() {
    return {
      chartjsData,
      filterOptions: [
        {
          value: "1W",
          label: "field.last7Day",
        },
        {
          value: "1M",
          label: "field.last30Day",
        },
        {
          value: "1Y",
          label: "field.last12Month",
        },
      ],
      query: {
        period: this.$route.query.period || "1M",
      },
      chartData: {},
      key: 1,
      loading: false,
      data: {
        labels: [],
        datasets: [
          {
            data: [],
            label: "",
            borderColor: "#003594",
            lineTension: 0.5,
            pointStyle: "circle",
            backgroundColor: "#003594",
            fill: false,
            pointRadius: 1,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 5,
            pointBorderColor: "transparent",
            pointHoverBorderColor: "#fdfdfd",
            pointHoverBackgroundColor: "#003594",
            pointShadowOffsetX: 1,
            pointShadowOffsetY: 1,
            pointShadowBlur: 5,
            pointShadowColor: "rgba(0, 0, 0, 0.25)",
          },
        ],
      },
    };
  },
  mounted() {
    this.index();
  },
  methods: {
    changeDate(period) {
      this.query.period = period;
      this.updateQuerySting();
    },
    updateQuerySting() {
      this.$router.replace({ query: this.query });
    },
    index() {
      this.loading = true;
      DashboardRepository.paymentChart({
        period: this.query.period,
      })
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.chartData = { ...data };
            this.mapChartData(data);
          }
        })
        .catch((error) => {
          this.loading = false;
        })
        .then(() => {
          this.loading = false;
        });
    },
    mapChartData(data) {
      let labels = [];
      switch (this.query.period) {
        case "1H":
          for (let label of data.labels) {
            labels.push(
              moment(`${label}`).locale(this.currentLocale).format("hh:mm a")
            );
          }
          break;
        case "1D":
          for (let label of data.labels) {
            labels.push(
              moment(`${label}`).locale(this.currentLocale).format("hh:00 a")
            );
          }
          break;
        case "1W":
          for (let label of data.labels) {
            labels.push(
              moment(`${label}`).locale(this.currentLocale).format("dddd")
            );
          }
          break;
        case "1M":
          for (let label of data.labels) {
            labels.push(
              moment(`${label}`).locale(this.currentLocale).format("DD MMM")
            );
          }
          break;
        case "1Y":
          for (let label of data.labels) {
            labels.push(
              moment(`${label}`).locale(this.currentLocale).format("MMM YYYY")
            );
          }
          break;
      }
      this.data.labels = [...labels];
      this.data.datasets[0].data = [...data.data];
      this.data.datasets[0].label = this.$t("field.payment");
      this.key++;
    },
  },
};
</script>
