<template>
  <div>
    <load-profile />

    <section id="dashboard-analytics">
      <b-row class="match-height">
        <b-col md="12" lg="6" xl="3">
          <StatisticCardHorizontal
            :loading="loading"
            :statistic="totalReserveAmount"
            :title="$t('field.reservePrice')"
            color="info"
          />
        </b-col>
        <b-col md="12" lg="6" xl="3">
          <StatisticCardHorizontal
            :loading="loading"
            :statistic="totalFinalAmount"
            :title="$t('field.finalPrice')"
            color="primary"
          />
        </b-col>
        <b-col md="12" lg="6" xl="3">
          <StatisticCardHorizontal
            :loading="loading"
            :statistic="totalPaidAmount"
            :title="$t('field.payAmount')"
            color="success"
          />
        </b-col>
        <b-col md="12" lg="6" xl="3">
          <StatisticCardHorizontal
            :loading="loading"
            :statistic="totalPendingAmount"
            :title="$t('field.remainAmount')"
            color="warning"
          />
        </b-col>
      </b-row>
      <b-row class="match-height">
        <b-col>
          <payment-chart></payment-chart>
        </b-col>
      </b-row>
      <b-row class="match-height">
        <b-col md="6">
          <vehicle :items="vehicles" :loading="loading"></vehicle>
        </b-col>
        <b-col md="6">
          <role :items="roles" :loading="loading"></role>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import Role from "./Role";
import Vehicle from "./Vehicle";
import PaymentChart from "./PaymentChart";
import StatisticCardHorizontal from "./StatisticCardHorizontal";
import Repository from "@/repositories/RepositoryFactory";

const DashboardRepository = Repository.get("dashboard");

export default {
  components: {
    BRow,
    BCol,

    Role,
    Vehicle,
    PaymentChart,
    StatisticCardHorizontal,
  },
  data() {
    return {
      loading: true,
      roles: [],
      vehicles: [],
      totalReserveAmount: 0,
      totalFinalAmount: 0,
      totalPaidAmount: 0,
      totalPendingAmount: 0,
    };
  },
  mounted() {
    this.index();
  },
  methods: {
    index() {
      this.loading = true;
      DashboardRepository.index()
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.totalReserveAmount = data.totalReserveAmount;
            this.totalFinalAmount = data.totalFinalAmount;
            this.totalPaidAmount = data.totalPaidAmount;
            this.totalPendingAmount = data.totalPendingAmount;
            this.roles = [
              ...data.roles.sort((a, b) => b.quantity - a.quantity),
            ];
            this.vehicles = [...data.vehicles];
          }
        })
        .catch((error) => {
          this.loading = false;
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script>
